import AnalysisCard from "../ToolsCard.vue";
import NeoLoader from "@/components/loader";
import NeoInput from "@/components/input";
import NeoButton from "@/components/button";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import OsintAxios from "@/axios/osint.js";
import SaasAxios from "@/axios";
import {mapGetters, mapMutations, mapActions} from "vuex";
import {DateTime} from "luxon";
import {get} from "lodash";
import neoSwitch from "@/components/toggle-switch";
import {getMonitoring} from "@/utils/functions";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "@/utils/functions";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import NlpAnalysisHighlighting from "@/components/nlp-analysis-highlighting";

export default {
    name: "flickr",
    mixins: [toolsHelper],
    components: {
        AnalysisCard,
        NeoLoader,
        NeoInput,
        NeoButton,
        VueMultiselect,
        NeoAnalyse,
        neoSwitch,
        "download-csv": JsonCSV,
        "nlp-analysis-highlighting": NlpAnalysisHighlighting,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            case_id: "",
            valueInput: "",
            allData: [],
            csvData: {
                flickr: [],
            },
            isLoading: false,
            allPhotos: [],
            expanded: false,
            photosLoading: false,
            dataLoaded: false,
            selectedItem: null,
            flickrUsers: [],
            showResult: false,
            selectVal: "",
            searchClicked: false,
            tabName: "",
            request_id: "",
        };
    },
    computed: {
        ...mapGetters(["getProduct", "getSelectedToolQuery", "getMonitoringDateFilter", "getReadOnlyMode", "getResearchData", "getAllToolsData", "getAllMonitoringData", "getCaseEmails"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvFields() {
            return ["nsid", "name", "profile_image", "profile_url", "pro", "gender", "location", "tz", "description", "photos", "first_photo"];
        },

        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },

        // this.adverseResults
        checkMarkAllDone() {
            if (this.allData && this.allData.length) return this.allData.every((result) => result.changes_viewed);
            else return false;
        },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "flickr-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "flickr-tool");
    },
    watch: {
        // allData(newVal, prevVal) {
        //     if (newVal) {
        //         this.$emit("searchResult", { data: newVal, source: "flickr", searchType: "email" });
        //     }
        // },
        // $route(newRoute, prevRoute) {
        //     if (newRoute.params.toolName === "flickr-tool") {
        //         if (newRoute.query.q !== this.valueInput) this.redirectToQuery();
        //     }
        // },
        "$route.query.q"(to, from) {
            if (to !== from && to !== this.valueInput) {
                this.redirectToQuery();
            }
        },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },

    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseEmails"]),

        async getToolData() {
            this.flickrUsers = await this.getDropdownData("email");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: "Email",
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "flickr", [...this.flickrUsers], data);
            allChecked = [...this.flickrUsers]?.length ? ([...this.flickrUsers].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        setInput() {
            this.searchType = "email";
            this.valueInput = this.selectedItem.value;
            // this.handleSearch();
        },

        setTheCustomInput(value) {
            this.searchType = "email";
            this.valueInput = value;
            this.selectedItem = {
                value: value.trim(),
            };
            // this.handleSearch();
        },

        async handleSearch() {
            this.showResult = false;
            this.searchClicked = true;
            const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

            if (emailRegex.test(this.valueInput.trim())) {
                const obj = {
                    email: this.valueInput.trim(),
                };
                if (this.isMonitoringTab) {
                    await this.monitoringSearch();
                    return;
                }
                this.$emit("searched", {data: {email: this.valueInput}, source: "flickr"});
                this.isLoading = true;
                this.allPhotos = [];
                this.allData = [];
                this.expanded = false;
                this.photosLoading = false;
                this.dataLoaded = false;

                if (this.isMonitoringTab) {
                    await this.monitoringSearch();
                    return;
                }

                const data = await OsintAxios.post("/tools/flickr", obj, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                });

                this.isLoading = false;
                await this.setSearchedUser(this.valueInput);
                if (data?.data?.status) {
                    this.allData = data.data.data;
                    this.csvData.flickr = this.convertToCSV(this.allData);
                    this.showResult = true;
                    this.$forceUpdate();
                    this.SET_ALL_TOOLS_DATA({
                        ...this._data,
                        "tool-name": "flickr-tool",
                    });
                } else {
                    this.$toast.error(data?.data?.message);
                }
                this.request_id = data?.data?.query_id;
                this.redirectTo();
                this.$emit("searchResult", {data: this.allData, source: "flickr", searchType: "email"});
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        getFormattedTime(ts, format = "EEE, dd MMM yyyy, hh:mm:ss a") {
            if (!ts) {
                return "";
            }
            let dt = DateTime.fromFormat(ts, "yyyy-MM-dd hh:mm:ss");
            return dt.toFormat(format);
        },
        getResultFormatedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
        getFormattedTimeSeconds(ts, format = "EEE, dd MMM yyyy, hh:mm:ss a") {
            if (!ts) {
                return "";
            }
            let dt = DateTime.fromSeconds(parseInt(ts));
            return dt.toFormat(format);
        },
        getMainFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("EEE, dd MMM yyyy, hh:mm:ss a");
        },
        async fetchMoreData(nsid) {
            this.dataLoaded = false;
            this.expanded = !this.expanded;
            if (!this.dataLoaded && this.expanded) {
                this.photosLoading = true;
                const {data} = await OsintAxios.post(
                    "/tools/flickr/photos",
                    {query: nsid},
                    {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-query-id": this.request_id,
                        },
                    }
                );
                this.dataLoaded = true;
                this.photosLoading = false;
                if (data.status) {
                    this.allPhotos = data.data;
                } else {
                    this.$toast.error("No photos found for user");
                }
            }
        },

        async setSearchedUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "flickr",
                val_type: this.selectedItem?.val_type || "email",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`flickrUsers`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`flickrUsers`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const value = Object.values(selected_query?.query)[0];
                this.searchKeyword = value;
                this.valueInput = value;
                this.selectedItem = {
                    value: value,
                };
                this.selectVal = value;
                if (!dataRes) this.handleSearch();
            } else {
                this.redirectTo();
            }
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            this.searchKeyword = this.selectVal;
            this.selectedItem = {
                value: this.selectVal.trim(),
            };
            this.searchType = "email";
            this.valueInput = this.selectVal.trim();
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectVal = event;
        },
        getMonitoring,
        getBackgroundStatus,
        getResultsID,

        async monitoringSearch() {
            let sources = ["flickr-tool"];
            this.isLoading = true;
            this.expanded = false;
            this.photosLoading = false;
            this.allPhotos = [];
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            this.allData = values;
            this.csvData.flickr = this.convertToCSV(this.allData);
            this.showResult = true;
            this.isLoading = false;
        },

        async handleMonitoring(index) {
            // event.stopPropagation();
            let data;
            if (index >= 0) data = this.allData[index];
            this.allData[index].monitoring_status = !this.allData[index].monitoring_status;
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id: data.doc_id,
                card_source: this.$route.params.toolName,
                status: data.monitoring_status ? "enabled" : "disabled",
            });
        },

        async handleDone({target}, index) {
            let data;
            if (index >= 0) data = this.allData[index];
            this.allData[index].changes_viewed = !this.allData[index].changes_viewed;
            this.$forceUpdate();
            let sources = ["flickr-tool"];
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id: data.monitoring_id,
                visited: target.checked,
            });
        },

        async handleMarkAsAllDone(event) {
            this.allData.forEach((result, index) => {
                this.allData[index].changes_viewed = event.target.checked;
            });
            this.$forceUpdate();
            let sources = ["flickr-tool"];
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources,
                mark_all_as_visited: event.target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
    },
};
